@import '../../styles/imports.scss';

.header{
    height: 40px;
    background: $color-primary;
    color: $color-text-white;
    line-height: 40px;
    padding: 0 15px;
    position: relative;
}

.backButton{
    cursor: pointer;
    display: inline-block;
}

.pageTitle{
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
}

.pageUpperRightMenu{
    float: right;
}