.formGroup{
    margin-bottom: 15px;

    & > label{
        display: block;
        margin-bottom: 5px;
    }
}

:global{
    .datepicker.ios{
        width: 400px;
        height: 240px;
        max-width: 90%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        font-family: Chinese Quote,-apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif;
    }
}