@import '../shared/styles/imports.scss';

.wrapper{
    text-align: center;
    padding-top: 20vh;
}

.linkButton{
    @extend %link-button;
}

.inputText{
    margin-bottom: 15px !important;
}

.submitBtn{
    margin-bottom: 15px !important;
}

.versioning{
    position: fixed;
    right: 10px;
    bottom: 10px;
}

.configItem{
    margin-bottom: 20px;
}

.sectionTitle{
    margin: 20px 0 30px 5px;
}

.flexCardsWrapper{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}

.flexCardsChild{
    width: calc((100% / 3) - 16px);
    margin-right: 16px;
    margin-bottom: 16px;

    @media screen and (max-width: 768px) {
        width: 100%;
        margin-right: 0;
    }
}