@import '../../shared/styles/imports.scss';

.formItem{
    display: block;
    margin: 25px 0;

    label{
        display: block;
        margin-bottom: 7px;
        font-weight: 500;
    }
}

.formControl{
    width: 100%;
}

.formContainer{
    max-width: 100%;
    width: 500px;
    padding: 0 30px;
    margin: 0;

    h2{
        text-align: center;
        margin: 30px 0;
        text-transform: uppercase;
    }
}

.durationButtons{
    button{
        margin-right: 10px;
    }
}

.actionButton{
    margin-right: 10px;
    margin-bottom: 10px;
}