@import '../shared/styles/imports.scss';

.formItem{
    margin-bottom: 20px;

    & > label{
        display: block;
        font-weight: 500;
        margin-bottom: 10px;
    }
}

.activeShiftTable{
    width: 400px;
    max-width: 100%;
    border-collapse: collapse;

    td{
        border: 1px solid rgba(0,0,0,0.1);
        padding: 10px;
    }
}

.shiftHistoryTable{
    width: 400px;
    max-width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;

    td{
        //border: 1px solid rgba(0,0,0,0.1);
        padding: 5px 0;
    }
}

.printHistory{
    background: white;
    padding: 15px;
    width: 400px;
    max-width: 100%;
    margin: auto;

    @media print {
        padding: 0;
        width: 100%;
    }

    h2{
        text-align: center;
    }

    table{
        table-layout: auto;
        max-width: 100%;
        width: 100%;

        td{
            @media print {
                padding: 3px;
                vertical-align: top;
            }
        }
    }
}

.printHistoryButtons{
    margin: 20px 0;
    text-align: center;

    @media print {
        display: none;
    }
}