@import '../../shared/styles/imports.scss';

.wrapper{
    @media print {
        display: none;
    }
}

.logoWrapper{
    text-align: center;
    border-bottom: 1px solid #EEEEEE;
    padding: 15px 0;
}

.logo{
    max-width: 80%;
    height: 25px;
    margin: 5px auto;
}

.menu{
    min-height: calc(100vh - 66px);
    border-right: 0;
}