@import '../../shared/styles/imports.scss';

.formItem{
    display: block;
    margin-bottom: 15px;
}

.paymentRemainder{
    color: $color-red;
    font-size: 35px;
    line-height: 50px;
    font-weight: 500;
}

.paymentRemainderChange{
    color: $color-green;
    font-size: 35px;
    line-height: 50px;
    font-weight: 500;
}

.newPayment{
    border: 1px solid rgb(217, 217, 217);
    padding: 15px;
    border-radius: 4px;
    margin-top: 15px;
    max-width: 100%;
    //width: 500px;
}

.linkButton{
    @extend %link-button;
    color: $color-red;

    &:hover{
        color: $color-red;
        opacity: 0.8;
    }
}