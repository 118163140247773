@import '../../shared/styles/imports.scss';

.trxList{
    padding: 0;
    height: calc(100vh - 174px);
    overflow: auto;
}

.trxItem{
    padding: 10px 15px;
    //cursor: pointer;
    border-top: 1px solid rgba(0,0,0,0.1);
    position: relative;

    //&:hover{
    //    background: transparentize($color-primary, 0.9);
    //}

    &:last-child{
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }
}

.trxItemAvatar{
    float: left;
    margin: 0 15px 0 0 !important;
    cursor: pointer;
}

.trxCustomerName{
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
}

.trxInfo{
    font-size: 12px;
    cursor: pointer;
}

.trxLabel{
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
}

.emptyPendingTrx{
    padding: 15px;
    border-top: 1px solid #EEE;
}

.trxAction{
    position: absolute;
    top: 15px;
    right: 15px;
}