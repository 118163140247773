@import '../shared/styles/imports.scss';

.wrapper{
    text-align: center;
}

.pageTitle{
    text-align: center;
    color: $color-text-gray;
    margin-bottom: 18px;
    text-transform: uppercase;
}

.logo{
    max-width: 80%;
    width: 250px;
    margin: 15vh auto 30px auto;
}

.listWrapper{
    max-width: 100%;
    width: 500px;
    text-align: left;
    margin: auto;
}

.outletIcon{
    background-color: $color-primary;
}

.outletName{
    @extend  %link-button;
}