@import '../../shared/styles/imports.scss';

.sider{
    border-right: 1px solid #EEE;

    @media print {
        display: none;
    }
}

.menuDrawer{
    padding: 0;

    @media print {
        display: none;
    }

    :global{
        .ant-drawer-body{
            padding: 0;
        }
    }
}

.header{
    background: white;
    position: relative;
    font-weight: 500;
    padding-left: 30px;
    padding-right: 30px;

    @media print {
        display: none;
    }
}

.footer{
    padding-left: 30px;
    padding-right: 30px;

    @media print {
        display: none;
    }
}

.headerRight{
    float: right;

    @media screen and (max-width: $breakpoint-lg-widest){
        float: none;
    }
}

.mobileMenuToggle{
    display: none;

    @media screen and (max-width: $breakpoint-lg-widest){
        float: right;
        margin-top: 15px;
        display: inline-block;
    }
}

.nonMobile{
    display: inline-block;

    @media screen and (max-width: $breakpoint-lg-widest){
        display: none;
    }
}

/*
:global{
    .ant-menu-inline .ant-menu-item:first-child{
        margin-top: 0;
    }
}*/
