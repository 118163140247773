@import '../shared/styles/imports.scss';

.tdNo{
    width: 5%;
}

.tdQty{
    width: 5%;
}

.tdGrossSales{
    width: 12%;
}

.tdDiscountValue{
    width: 12%;
}

.tdTotalCommission{
    width: 12%;
}

.tdCommissionBaseValue{
    width: 12%;
}