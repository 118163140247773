@import '../shared/styles/imports.scss';

.previewTable{
    border: 1px solid rgba(0,0,0,0.1);
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin-bottom: 20px;

    td{
        border: 1px solid rgba(0,0,0,0.1);
        padding: 10px;
    }
}

.previewItem{
    text-align: center;
    line-height: 2em;

    img{
        max-width: 100px;
        border: 1px solid rgba(0,0,0,0.1);
    }
}

.successMessage{
    text-align: center;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    max-width: 100%;
}

.redeemItemDiv{
    text-align: center;
    cursor: pointer;

    span{
        border: 1px solid rgba(0,0,0,0.1);
        margin-bottom: 10px;
    }
}

.formGroup{
    margin-bottom: 15px;

    & > label{
        display: block;
        margin-bottom: 5px;
    }
}