@import '../../shared/styles/imports.scss';

.outerWrapper{
    padding: 0;
    margin-right: -1px;
    border-right: 1px solid #EEE;
}

.itemSearch{
    background: $color-search-bg-gray;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding: 5px;
}

.addCustomItemBtn{
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    padding: 15px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% * 16 / 24);
    border-top: 1px solid rgba(0,0,0,0.1);
    background: white;
}

.categoryName{
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 20px;

    @media screen and (max-width: $breakpoint-lg-widest){
        font-size: 18px;
    }
}

.itemList{
    padding: 15px 15px 100px 15px;
}

.qtyWrapper{
    display: block;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    user-select: none;
}

.qtyDecrease{
    @extend %link-button;
    display: inline-block;
    margin-right: 50px;
}

.qtyIncrease{
    @extend %link-button;
    display: inline-block;
    margin-left: 50px;
}