@import '../../shared/styles/imports.scss';

.trxList{
    padding: 0;
    height: calc(100vh - 174px);
    overflow: auto;
}

.trxItem{
    padding: 10px 15px;
    cursor: pointer;
    border-top: 1px solid rgba(0,0,0,0.1);

    &:hover{
        background: transparentize($color-primary, 0.9);
    }

    &:last-child{
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }
}

.trxItemAvatar{
    float: left;
    margin: 0 15px 0 0 !important;
}

.trxCustomerName{
    font-size: 15px;
    font-weight: 500;
}

.trxInfo{
    font-size: 12px;
}

.emptyCompletedTrx{
    padding: 15px;
    border-top: 1px solid #EEE;
}

.viewTrxBtn{
    color: $color-primary;
    @extend %link-button;
    cursor: pointer;
}

.voidBtn{
    color: $color-red;
    @extend %link-button;
    cursor: pointer;
}

.voidLabel{
    color: $color-red;
}