@import '../../shared/styles/imports.scss';

.outerWrapper{
    padding: 15px;
}

.itemListTable{
    width: 100%;
    table-layout: fixed;
}

.itemRow{
    cursor: pointer;

    &:first-child{
        td{
            padding-top: 0;
        }
    }

    td{
        padding-top: 10px;
    }
}

.itemSubRow{
    td{
        padding-top: 0;
    }
}

.itemName{
    width: 100%;
}

.segmentTitle{
    margin-top: 10px;
    text-align: center;
}

.segmentTotal{
    line-height: 2em;
}

.itemQty{
    width: 30px;
}

.itemSubTotal{
    width: 120px;
    text-align: right;
}

.itemRemove{
    width: 25px;
    text-align: right;

    i{
        color: $color-red;
        cursor: pointer;

        &:hover{
            color: darken($color-red, 10%);
        }
    }
}

.itemSub{
    font-size: 12px;
    color: $color-text-gray;
}

.receiptDivider{
    width: 100%;
    height: 0;
    margin: 10px 0;
    border-top: 1px dashed black;
}

.outerWrapperPrint8cm{
    font-size: 12px;

    .itemSub{
        font-size: 10px;
    }
}

.outerWrapperPrint6cm{
    font-size: 10px;

    .itemSub{
        font-size: 8px;
    }
}

.outerWrapperPrintTablet{
    //font-weight: bold;
    font-size: 14px;

    .itemSub{
        font-size: 12px;
    }
}

.outerWrapperPrint{
    padding: 15px;
    font-family: monospace;
    color: black;
    line-height: 1.1em;
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media screen {
        margin-top: 10vh;
        border: 1px solid rgba(0,0,0,0.1);
    }

    @media print {
        max-width: 100%;
    }

    table{
        td{
            vertical-align: top;
        }
    }

    .itemName{
        color: black;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    .itemSub{
        color: black;
    }

    .receiptDivider{
        margin: 5px 0;
    }

    .itemQty{
        width: 20px;
    }

    .itemSubTotal{
        width: 110px;
    }

    .itemQty6cm{
        width: 20%;
    }

    .itemSubTotal6cm{
        width: 75%;
        text-align: right;
    }

    .itemRow{
        cursor: default;

        td{
            padding-top: 5px;
        }
    }
}

.headerTable{
    width: 100%;
    table-layout: fixed;

    td:not(.brandName){
        max-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.alignRight{
    text-align: right;
}

.brandName{
    text-align: center;
    font-size: 21px;
    line-height: 1.5em;
    padding: 20px 0 0 0;
}

.outletName{
    text-align: center;
    padding: 10px 0 30px 0;
}

.linkBtn{
    @extend %link-button;

    font-size: 10px;
    color: $color-red;
    &:hover, &:focus{
        color: darken($color-red, 10%);
    }
}

.tabletActionButtons{
    text-align: center;
    margin: 30px auto;

    @media print {
        display: none;
    }
}