@import '../shared/styles/imports.scss';

.newTrxList{
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;

    @media screen and (max-width: $breakpoint-lg-widest) {
        border-bottom: 1px solid rgba(0,0,0,0.1);
        padding: 10px;
    }
}

.newTrxBtn{
    text-align: center;
    padding: 49px 0;
    font-size: 25px;
    line-height: 25px;
    cursor: pointer;
    border-bottom: 1px solid rgba(0,0,0,0.1);

    @media screen and (max-width: $breakpoint-lg-widest) {
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 8px;
        padding: 20px 15px;
        margin: 10px;
        display: inline-block;
        box-shadow: 0 8px 6px -6px #CCC;
    }


    &:hover{
        background: #F9F9F9;
    }
}

.newTrxIcon{
    font-size: 25px;
    //color: $color-green;
    line-height: 25px;
    margin: 0 20px 0 0;
    vertical-align: middle;
}

.trxListPanel{
    h2{
        text-align: center;
        margin: 30px 0;
        text-transform: uppercase;
    }
}

.trxListSearch{
    padding: 10px;
    border-top: 1px solid #EEE;
}

.middlePanel{
    min-height: calc(100vh - 40px);
    border-left: 1px solid #EEE;
    border-right: 1px solid #EEE;
}

.rightPanel{
    height: calc(100vh - 40px);
    border-left: 1px solid #EEE;
    overflow: auto;
    padding-bottom: 150px;
}

.leftPanel{
    height: calc(100vh - 40px);
    border-right: 1px solid rgba(0,0,0,0.1);
    overflow: auto;
}

.trxLabel{
    padding: 6px;
    border-bottom: 1px solid #EEE;
}

.topUpDepositForm{
    width: 500px;
    max-width: 100%;
    padding: 50px;
    margin: 10vh auto 30px auto;
    display: block;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 4px;

    h2{
        text-align: center;
        margin-bottom: 30px;
    }
}

.salesMenu{
    padding: 10px;
    text-align: center;
}

.actionArea{
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc((100% * 8 / 24) - 1px);
    background: white;
    border-top: 1px solid rgba(0,0,0,0.1);
    text-align: center;
}

.redeemItemBanner{
    background: lightyellow;
    padding: 5px 15px;
    text-align: left;
}

.deleteRedeemItemBtn{
    @extend %link-button;

    font-size: 10px;
    color: $color-red;
    &:hover, &:focus{
        color: darken($color-red, 10%);
    }
}

.actionAreaWide{
    padding: 15px;
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc((100% * 16 / 24) - 1px);
    background: white;
    border-top: 1px solid rgba(0,0,0,0.1);
    text-align: center;

    @media screen and (max-width: $breakpoint-lg-widest) {
        position: relative;
        width: 100%;
    }
}

.action{
    display: inline-block;
    margin: 20px 7px;
    text-align: center;
    cursor: pointer;

    @media screen and (max-width: $breakpoint-lg-widest) {
        font-size: .8em;
    }

    & > span{
        display: block;

        span{
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
}

.actionIcon{
    width: 56px;
    height: 56px;
    margin: auto;

    @media screen and (max-width: $breakpoint-lg-widest) {
        width: 36px;
        height: 36px;
    }

    svg{
        width: 28px;
        height: 28px;
        margin-top: 50%;

        @media screen and (max-width: $breakpoint-lg-widest) {
            width: 18px;
            height: 18px;
            //margin-top: 30%;
        }
    }
}

.paymentWrapper{
    padding: 24px 24px 200px 24px;
}

.formItem{
    display: block;
    margin-top: 30px;
}

.finalStepWrapper{
    margin: 20vh auto 0 auto;
    text-align: center;
    width: 500px;
    max-width: 90%;
}

.changeLabel{
    font-size: 15px;
    margin-bottom: 10px;
}

.changeValue{
    font-size: 30px;
    margin-bottom: 30px;
}

.receiptPanel{
    height: calc(100vh - 40px);
    border-right: 1px solid rgba(0,0,0,0.1);
    border-left: 1px solid rgba(0,0,0,0.1);
    overflow: auto;
}

.no-print, .no-print *{
    @media print{
        display: none !important;
    }
}





.membershipItem{
    margin: 10px;
    padding: 5px;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: 0 0 3px 1px rgba(0,0,0,0.1);
    display: inline-block;
    cursor: pointer;
    width: 150px;

    img{
        display: block;
        max-width: 100%;
    }

    .membershipName{
        margin-top: 5px;
    }
}