@import "./variables.scss";

%link-button{
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    text-decoration: none;
    font: inherit;
    color: $color-primary;

    &:hover, &:focus{
        color: darken($color-primary, 10%);
    }
}