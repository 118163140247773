@import '../../shared/styles/imports.scss';

.formItem{
    margin-top: 15px;
    margin-bottom: 15px;
}

.qtyWrapper{
    display: block;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    user-select: none;
}

.qtyInput {
    text-align: center !important;
}

.qtyDecrease{
    @extend %link-button;
    display: inline-block;
    margin-right: 50px;
}

.qtyIncrease{
    @extend %link-button;
    display: inline-block;
    margin-left: 50px;
}

.workerTable{
    width: 100%;

    tbody{
        tr{
            td:first-child{
                width: 80%;
            }

            td:last-child{
                width: 20%;

                input{
                    width: 70%;
                }
            }
        }
    }
}

.commissionWorkerPerson{
    line-height: 1.5em;
}

.deleteCommissionWorkerBtn{
    @extend %link-button;
    display: inline-block;
    color: $color-red;
    margin-left: 5px;
    font-size: 10px;

}