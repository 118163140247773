@import '../../styles/imports.scss';

.posItemWrapper{
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 150px;
    vertical-align: top;
    cursor: pointer;

    @media screen and (max-width: $breakpoint-lg-widest) {
        width: 100px;
    }

    img{
        max-width: 100%;
        width: 150px;
        height: 125px;
        object-fit: cover;

        @media screen and (max-width: $breakpoint-lg-widest) {
            width: 100px;
            height: 75px;
        }
    }
}

.posItemName{
    padding: 5px;
    text-align: center;
    border: 1px solid rgba(0,0,0,0.1);

    @media screen and (max-width: $breakpoint-lg-widest) {
        font-size: .8em;
        word-wrap: break-word;
    }
}