// Colors
$color-primary: #40B9EB;
$color-secondary: #00D6CE;
$color-red: #ED6D48;
$color-green: #42B549;
$color-yellow: #F0CA00;
$color-black: #000000;
$color-white: #FFFFFF;
$color-text-black: #333333;
$color-text-white: #FFFFFF;
$color-text-gray: #9B9B9B;
$color-bg-gray: #DDDDDD;
$color-search-bg-gray: #F5F5F5;

$font-family-heading: 'Roboto Condensed', sans-serif;

$breakpoints-mobile: 768px;

$breakpoint-xs-narrowest: 0;
$breakpoint-xs-widest: 576px;
$breakpoint-sm-narrowest: 577px;
$breakpoint-sm-widest: 768px;
$breakpoint-md-narrowest: 769px;
$breakpoint-md-widest: 992px;
$breakpoint-lg-narrowest: 993px;
$breakpoint-lg-widest: 1200px;

$z-indexes: (
    "outdated-browser",
    "modal",
    "site-header",
    "page-wrapper",
    "site-footer"
);