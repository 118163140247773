@import '../shared/styles/imports.scss';

.appointmentControls{
    margin-bottom: 20px;

    .formItem{
        label{
            margin-right: 8px;
            font-weight: 500;
        }
    }
}

.appointmentWrapper{
    overflow: auto;
    height: 80vh;
}

.appointmentSchedule{
    display: inline-block;
    width: max-content;
    position: relative;
    //border-left: 1px solid #eef0f2;
    //box-shadow: 0 0 3px 0 #eef0f2;
}

.timePointerCol{
    position: relative;
    display: inline-block;
    vertical-align: top;
    //border-top: 1px solid #eef0f2;
    width: 100px;
}

.timePointerColClone{
    position: absolute;
    left: 0;
    top: 51px;
    z-index: 10;
    background: white;

    .timePointerColHead{
        position: static;
    }
}

.timePointerColHead{
    position: sticky;
    top: 0;
    z-index: 5;
    text-align: right;
    font-weight: bold;
    padding: 10px;
    width: 100px;
    height: 50px;
    //border-right: 1px solid #eef0f2;
    //border-bottom: 1px solid #d1d6dc;
    border-collapse: collapse;
    background: white;
}

.timePointerCell{
    width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    //width: 100%;
    height: 120px;
    text-align: right;
    font-weight: bold;
    //border-right: 1px solid #eef0f2;
    //border-bottom: 1px solid #d1d6dc;
    border-right: 1px solid #eef0f2;
    border-collapse: collapse;
}

.appointmentScheduleCol{
    position: relative;
    display: inline-block;
    vertical-align: top;
    //border-top: 1px solid #eef0f2;
    width: 250px;

    &:last-child{
        .colHead{
            border-right: 0;
        }
    }
}

.colHead{
    position: sticky;
    top: 0;
    z-index: 5;
    padding: 10px;
    height: 50px;
    font-weight: bold;
    text-align: center;
    border-right: 1px solid #eef0f2;
    border-bottom: 1px solid #d1d6dc;
    border-collapse: collapse;
    background: white;
}

.appointmentScheduleCell{
    position: relative;
    padding: 0;
    width: 100%;
    //overflow: hidden;
    border-right: 1px solid #eef0f2;
    border-bottom: 1px solid #d1d6dc;
    border-collapse: collapse;
}

.appointmentScheduleSubCell{
    display: inline-block;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
    border-bottom: 1px solid #eef0f2;
    border-collapse: collapse;

    &:last-child{
        border-bottom: 0;
    }

    &:hover{
        background: rgba(64,185,235,0.1);
        cursor: pointer;

        span{
            visibility: visible;
        }
    }

    span{
        visibility: hidden;
    }
}

.appointmentCard{
    position: absolute;
    overflow: auto;
    width: 100%;
    left: 0;
    padding: 10px;
    background: lighten($color-primary, 30%);
    border-radius: 4px;
    z-index: 1;
}

.appointmentCardActions{
    .deleteAppointmentBtn{
        color: $color-red;
    }

    button{
        margin-right: 10px;
    }
}

.editAppointmentBtn{
    position: absolute;
    right: 0;
    top: -5px;
}