@import '../shared/styles/imports.scss';

.formItem{
    margin-bottom: 20px;

    & > label{
        display: block;
        font-weight: 500;
        margin-bottom: 10px;
    }
}