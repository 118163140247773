@import '../shared/styles/imports.scss';

.previewTable{
    border: 1px solid rgba(0,0,0,0.1);
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin-bottom: 20px;

    td{
        border: 1px solid rgba(0,0,0,0.1);
        padding: 10px;
    }
}

.formGroup{
    margin-bottom: 15px;

    & > label{
        display: block;
        margin-bottom: 5px;
        font-weight: 500;
    }
}

.formItem{
    margin-bottom: 20px;

    & > label{
        display: block;
        font-weight: 500;
        margin-bottom: 10px;
    }
}

:global{
    .datepicker.ios{
        width: 400px;
        height: 240px;
        max-width: 90%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        font-family: Chinese Quote,-apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif;
    }

    .react-html5-camera-photo>img, .react-html5-camera-photo>video{
        max-width: 100%;
    }
}

.trxHistoryAffix > div{
    max-height: 90vh;
    overflow: auto;
}

.trxHistoryCard{
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;

    &:hover{
        background: rgba(0,0,0,0.1);
        cursor: pointer;
    }
}