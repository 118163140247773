.currentCustomer{
    padding: 10px 15px;
    cursor: pointer;
}

.currentCustomerAvatar{
    float: left;
    margin: 0 15px 15px 0 !important;
}

.currentCustomerEditBtn{
    float: right;
    font-size: 20px;
    margin-top: 10px;
    margin-left: 15px;
}

.currentCustomerName{
    font-size: 15px;
    font-weight: 500;
}

.currentCustomerContact{
    font-size: 12px;
}

.formItem{
    margin-bottom: 15px;

    & > label{
        display: block;
        margin-bottom: 5px;
    }
}

.journalTable{
    td{
        padding: 10px 0;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        vertical-align: top;
    }
    td:first-child{
        width: 200px;
        font-weight: bold;
    }
}




:global{
    .datepicker-modal{
        z-index: 999999 !important;
    }
}