@import '../shared/styles/imports.scss';

.wrapper{
    text-align: center;
}

.pageTitle{
    text-align: center;
    color: $color-text-gray;
    margin-bottom: 18px;
    text-transform: uppercase;
}

.logo{
    max-width: 80%;
    width: 200px;
    margin: 15vh auto 30px auto;
}

.containerCard{
    display: block;
    width: 325px;
    max-width: 100%;
    margin: auto !important;
}

.inputText{
    margin-bottom: 15px !important;
}

.submitBtn{
    margin-bottom: 15px !important;
}

.forgotPassword{
    text-align: center;

    button{
        @extend %link-button;
    }
}

.backToSignIn{
    text-align: center;

    button{
        @extend %link-button;
    }
}

.versioning{
    position: fixed;
    right: 10px;
    bottom: 10px;
}